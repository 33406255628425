/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
import { memo, FC } from 'react';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import {
  LinkedItem,
  DatoProductImage,
  ButtonGroup,
  Page,
} from '@framework/api/types';
import Hero from './Hero';

export type Props = {
  // Copy
  isShort?: boolean;
  isInverted?: boolean;
  eyebrowLogo?: DatoProductImage;
  eyebrow?: string;
  heading?: string;
  subheading?: string;
  copy?: string;
  copyMarkdown?: MDXRemoteSerializeResult;
  buttons?: ButtonGroup[];
  footnote?: string;
  footnoteMarkdown?: MDXRemoteSerializeResult;
  footnoteInternalLink?: LinkedItem;
  // Large Settings
  primaryImageLarge?: DatoProductImage;
  primaryImagePositionLarge?: 'Center' | 'Top' | 'Bottom';
  backgroundImageLarge?: DatoProductImage;
  backgroundImageExtraLarge?: DatoProductImage;
  backgroundColor?: {
    hex: string;
  };
  isFullbleedLarge?: boolean;
  contentPositionLarge?:
    | 'Left/Top'
    | 'Left/Middle'
    | 'Left/Bottom'
    | 'Right/Top'
    | 'Right/Middle'
    | 'Right/Bottom'
    | 'Center/Top'
    | 'Center/Middle'
    | 'Center/Bottom';
  textAlignmentLarge?: 'Left' | 'Center';
  // Small Settings
  primaryImageSmall?: DatoProductImage;
  primaryImagePositionSmall?: 'Center' | 'Top' | 'Bottom';
  backgroundImageSmall?: DatoProductImage;
  contentPositionSmall?: 'Top' | 'Bottom' | 'Middle';
  textAlignmentSmall?: 'Left' | 'Center';
  isFullbleedSmall?: boolean;
  buildPage?: Page;
};

// TODO: Rename this component as "hero" once we deprecate existing Hero
const HeroBlock: FC<Props> = ({
  isShort = false,
  isInverted,
  eyebrowLogo,
  eyebrow,
  heading,
  subheading,
  copyMarkdown,
  buttons,
  footnoteMarkdown,
  footnoteInternalLink,
  primaryImageLarge,
  primaryImagePositionLarge,
  isFullbleedLarge = false,
  backgroundColor,
  backgroundImageLarge,
  backgroundImageExtraLarge,
  contentPositionLarge,
  textAlignmentLarge,
  textAlignmentSmall,
  primaryImageSmall,
  primaryImagePositionSmall,
  backgroundImageSmall,
  contentPositionSmall,
  isFullbleedSmall = false,
  buildPage,
}) =>
  heading || subheading ? (
    <Hero
      isShort={isShort}
      isInverted={isInverted}
      eyebrowLogo={eyebrowLogo}
      eyebrow={eyebrow}
      heading={heading}
      subheading={subheading}
      copyMarkdown={copyMarkdown}
      buttons={buttons}
      footnoteMarkdown={footnoteMarkdown}
      footnoteInternalLink={footnoteInternalLink}
      primaryImageLarge={primaryImageLarge}
      primaryImagePositionLarge={primaryImagePositionLarge}
      backgroundColor={backgroundColor}
      backgroundImageLarge={backgroundImageLarge}
      backgroundImageExtraLarge={backgroundImageExtraLarge}
      contentPositionLarge={contentPositionLarge}
      isFullbleedLarge={isFullbleedLarge}
      textAlignmentLarge={textAlignmentLarge}
      textAlignmentSmall={textAlignmentSmall}
      primaryImageSmall={primaryImageSmall}
      primaryImagePositionSmall={primaryImagePositionSmall}
      backgroundImageSmall={backgroundImageSmall}
      contentPositionSmall={contentPositionSmall}
      isFullbleedSmall={isFullbleedSmall}
      buildPage={buildPage}
    />
  ) : null;

export default memo(HeroBlock);
