/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, useMemo } from 'react';

import { useRouter } from 'next/router';
import { useTranslation as t } from '@utils/hooks';
import { useHistory } from '@utils/history';
import trackChipsetModal from '@utils/amplitude/track/chipset-modal';

import { getRegion } from '@framework/api/utils/maxify';
import { ResponsiveImageFragment } from '@framework/api/utils/dato/responsive-image-fragment';
import GTM from '@utils/gtm';
import useBuildStore, {
  getBldEntryVariantId,
  getSetBLDEntryVariantId,
} from '@stores/use-build-store';
import {
  ROUTE_BUILD,
  ROUTE_BUILD_KEYBOARD,
  CONFIGURATOR_DEFAULT_PRICE,
  PRICE_OPTIONS_LABEL,
} from '@constants';
import Button from '@components/Button';
import RouteToBld from './RouteToBld';
import * as styles from './styles';

type FpsProps = {
  id: string;
  fpsSetting: string;
  games: {
    id: string;
    gameLogo: { responsiveImage: ResponsiveImageFragment };
    gameFps: string;
    gameFpsAmd?: string;
    gameFpsIntel?: string;
  }[];
};

export type FpsRangeCollection = {
  priceRangeFpsCollection: {
    buildPrice?: string;
    gameReference?: FpsProps[];
  };
};

type Props = {
  onClose?: () => void;
  destructive?: boolean;
  noSelect?: boolean;
  selectedChipset?: string;
  overrideLocale?: string;
  manuallyClose?: boolean;
  regionalPricing?: {
    price: string;
    id: string;
  }[];
  background?: 'transparent' | 'light' | 'dark';
  isReset?: boolean;
};

const BuildInterstitial: FC<Props> = ({
  regionalPricing,
  onClose,
  destructive,
  noSelect,
  selectedChipset,
  overrideLocale,
  manuallyClose,
  isReset,
}) => {
  const router = useRouter();
  const { locale, pathname } = router;
  const region = getRegion(locale);

  const isKeyboardBuild = pathname.includes('keyboard');

  const bldEntryVariantId = useBuildStore(getBldEntryVariantId);
  const setBldEntryVariantId = useBuildStore(getSetBLDEntryVariantId);

  //   Pricing to be able to reset build
  const [price, setPrice] = useState(CONFIGURATOR_DEFAULT_PRICE);
  const hasRegionalPricing =
    Array.isArray(regionalPricing) && regionalPricing?.length > 0;
  const sortedRegionPricing = useMemo(
    () =>
      hasRegionalPricing
        ? regionalPricing.sort((a, b) => (a.price > b.price ? 1 : -1))
        : [],
    [hasRegionalPricing, regionalPricing]
  );

  useEffect(() => {
    setPrice(
      sortedRegionPricing.length > 0
        ? sortedRegionPricing[0].price
        : PRICE_OPTIONS_LABEL[0].value
    );
  }, [sortedRegionPricing]);

  // clear the entry variant ID when the interstitial unmounts
  useEffect(
    () => () => {
      setBldEntryVariantId(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [loading, setLoading] = useState(false);

  const handleGtmEvent = (event: string): void => {
    GTM.dataLayer({
      dataLayer: {
        event,
      },
    });
  };

  const MESSAGE_DESTRUCTIVE_ACTION = t('custom_bld_destrutive_action');
  const MESSAGE_CONFIRM_RESET = t('custom_bld_confirm_reset');
  const MESSAGE_CONFIRM_RESET_KEYBOARD = t('custom_bld_keyboard_confirm_reset');
  const MESSAGE_CANCEL = t('cancel');
  const MESSAGE_CONTINUE = t('button_label_continue_to');

  const { last: lastPage } = useHistory();

  const handleModalMessage = (): string => {
    if (isKeyboardBuild) {
      return MESSAGE_CONFIRM_RESET_KEYBOARD;
    }
    return `${MESSAGE_CONFIRM_RESET} `;
  };

  const handleContinue = (): void => {
    if (isKeyboardBuild) {
      const route = `/${ROUTE_BUILD}/${ROUTE_BUILD_KEYBOARD}`;

      router.push(route);
    } else {
      setLoading(true);
      handleGtmEvent('Config_Notification');
    }
  };

  useEffect(() => {
    if (!isReset) {
      return;
    }
    // TODO: This fires for both KB and PC; Should there be another event for KB?
    GTM.dataLayer({
      dataLayer: {
        event: 'Config_PC_BuildRestart',
        chipset: selectedChipset,
        budget: price,
      },
    });
  }, [isReset]);

  const [hasBeenTrackedInAmplitude, setHasBeenTrackedInAmplitude] =
    useState(false);

  useEffect(() => {
    let timeout = null;

    if (!noSelect && !destructive && !hasBeenTrackedInAmplitude) {
      timeout = setTimeout(() => {
        trackChipsetModal({
          referrer: lastPage,
        });

        setHasBeenTrackedInAmplitude(true);
      }, 1);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [destructive, hasBeenTrackedInAmplitude, lastPage, noSelect, region]);

  return (
    <div className={styles.noSelectWrapper}>
      <div className={styles.noSelectFlexWrapper}>
        <div className={styles.noSelectInner}>
          {/* TODO - replace with a reacticon */}
          <svg
            className={styles.noSelectIcon}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <div className={styles.noSelectTextContainer}>
          <h3 className={styles.noSelectHeading}>
            {MESSAGE_DESTRUCTIVE_ACTION}
          </h3>
          <div className={styles.noSelectMessageWrapper}>
            <p className={styles.noSelectMessage}>{handleModalMessage()}</p>
          </div>
        </div>
      </div>
      <div className={styles.noSelectButtonWrapper}>
        <Button
          buttonStyle="primary"
          onClick={handleContinue}
          loading={loading}
          customMargin
          className={styles.smolButton}
        >
          {MESSAGE_CONTINUE}
        </Button>
        <Button
          buttonStyle="secondary"
          onClick={onClose}
          disabled={loading}
          customMargin
          className={styles.cancelButton}
        >
          {MESSAGE_CANCEL}
        </Button>
      </div>
      {loading && (
        <RouteToBld
          chipset={selectedChipset}
          product={parseInt(bldEntryVariantId?.toString(), 10)}
          price={price}
          destructive={destructive}
          overrideLocale={overrideLocale}
          manuallyClose={manuallyClose}
        />
      )}
    </div>
  );
};

export default BuildInterstitial;
