import {
  MAXIFY_REGION_NORTHERN_AMERICA,
  MAXIFY_REGION_EUROPE,
  MAXIFY_REGION_UNITED_KINGDOM,
  MAXIFY_REGION_OCEANIA,
  MAXIFY_REGION_NEW_ZEALAND,
  MAXIFY_REGION_ASIA,
} from './location';

export const DEFAULT_LANGUAGE_CODE = 'en';
export const GERMAN_LANGUAGE_CODE = 'de';
export const DUTCH_LANGUAGE_CODE = 'nl';
export const FRENCH_LANGUAGE_CODE = 'fr';
export const ITALIAN_LANGUAGE_CODE = 'it';
export const SPANISH_LANGUAGE_CODE = 'es';
export const JAPANESE_LANGUAGE_CODE = 'ja';

export const LANGUAGES = [
  {
    label: 'English',
    value: DEFAULT_LANGUAGE_CODE,
  },
  {
    label: 'Deutsch',
    value: GERMAN_LANGUAGE_CODE,
  },
  {
    label: 'Dutch',
    value: DUTCH_LANGUAGE_CODE,
  },
  {
    label: 'Français',
    value: FRENCH_LANGUAGE_CODE,
  },
  {
    label: 'Italiano',
    value: ITALIAN_LANGUAGE_CODE,
  },
  {
    label: 'Español',
    value: SPANISH_LANGUAGE_CODE,
  },
  {
    label: 'Japanese',
    value: JAPANESE_LANGUAGE_CODE,
  },
];

export const LANGS_PER_REGION = {
  [MAXIFY_REGION_NORTHERN_AMERICA]: [
    {
      label: 'English',
      value: DEFAULT_LANGUAGE_CODE,
    },
  ],
  [MAXIFY_REGION_UNITED_KINGDOM]: [
    {
      label: 'English',
      value: DEFAULT_LANGUAGE_CODE,
    },
  ],
  [MAXIFY_REGION_OCEANIA]: [
    {
      label: 'English',
      value: DEFAULT_LANGUAGE_CODE,
    },
  ],
  [MAXIFY_REGION_NEW_ZEALAND]: [
    {
      label: 'English',
      value: DEFAULT_LANGUAGE_CODE,
    },
  ],
  [MAXIFY_REGION_EUROPE]: [
    {
      label: 'English',
      value: DEFAULT_LANGUAGE_CODE,
    },
    {
      label: 'Deutsch',
      value: GERMAN_LANGUAGE_CODE,
    },
    {
      label: 'Français',
      value: FRENCH_LANGUAGE_CODE,
    },
    {
      label: 'Italiano',
      value: ITALIAN_LANGUAGE_CODE,
    },
    {
      label: 'Español',
      value: SPANISH_LANGUAGE_CODE,
    },
  ],
  [MAXIFY_REGION_ASIA]: [
    {
      label: 'Japanese',
      value: JAPANESE_LANGUAGE_CODE,
    },
  ],
};

export const UK_AU_NZ_REDIR_SLUGS = [
  '/category/gaming-pcs/prebuilt-pcs',
  '/category/gaming-pcs/build',
  '/category/gaming-pcs',
  '/collection/player-one',
  '/collection/player-two',
  '/collection/player-three',
  '/collection/pc-parts-and-accessories',
  '/product/player-one',
  '/product/player-one-prime',
  '/product/player-two',
  '/product/player-two-prime',
  '/product/player-three',
  '/product/player-three-prime',
  '/build/pc',
  '/refurbished/builds',
  '/refurbished/components',
  '/cart',
  '/product/player-two-amd-gamescom-edition',
  '/product/blitz-mode',
  '/product/exclusive-h510-pro-pc',
  '/product/exclusive-h510-plus-pc',
  '/product/player-one-3050-edition',
  '/product/exclusive-h710-plus-pc',
  '/product/exclusive-h710-pro-pc',
  '/product/player-two-starfield',
];
