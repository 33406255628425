import { track } from '@amplitude/analytics-browser';
import type { StartFromChipsetModalProperties } from '../types';

type TrackStartFromChipsetModalpArgs = {
  referrer?: string;
  chipset?: string;
  budget?: string;
};

const trackStartFromChipsetModal = ({
  referrer,
  chipset,
  budget,
}: TrackStartFromChipsetModalpArgs): void => {
  const eventProperties: StartFromChipsetModalProperties = {
    'page url': window.location.href,
    'referrer source': referrer,
    'chipset selection': chipset,
    'budget selection': budget,
  };

  track(
    'configurator - clicks start build from chipset modal',
    eventProperties
  );
};

export default trackStartFromChipsetModal;
