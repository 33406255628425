/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { ResponsiveImageFragment } from '@framework/api/utils/dato/responsive-image-fragment';
import type { DatoProduct } from '@framework/api/types';
import Modal from '@components/Modal';
import BuildReset from './Components/BuildReset';
import BuildStart from './Components/BuildStart';
import s from './BuildInterstitial.module.css';

type FpsProps = {
  id: string;
  fpsSetting: string;
  games: {
    id: string;
    gameLogo: { responsiveImage: ResponsiveImageFragment };
    gameFps: string;
    gameFpsAmd?: string;
    gameFpsIntel?: string;
  }[];
};

export type FpsRangeCollection = {
  priceRangeFpsCollection: {
    buildPrice?: string;
    gameReference?: FpsProps[];
  };
};

interface MarketingProductProps extends DatoProduct {
  collectionName: string;
  collectionSlug: string;
}

type Props = {
  marketingProducts?: MarketingProductProps[];
  open?: boolean;
  disableClose?: boolean;
  onClose?: () => void;
  destructive?: boolean;
  noSelect?: boolean;
  selectedChipset?: string;
  overrideLocale?: string;
  fpsByPrice?: FpsRangeCollection;
  manuallyClose?: boolean;
  regionalPricing?: {
    price: string;
    id: string;
  }[];
  background?: 'transparent' | 'light' | 'dark';
  isReset?: boolean;
};

const BuildInterstitial: FC<Props> = ({
  marketingProducts,
  open,
  disableClose,
  onClose,
  destructive,
  noSelect,
  selectedChipset,
  overrideLocale,
  fpsByPrice,
  manuallyClose,
  regionalPricing,
  background,
  isReset,
}) => (
  <div className={s.overlay}>
    <Modal
      open={open}
      disableClose={disableClose}
      onClose={onClose}
      ariaLabel={() => 'Start BLD'}
    >
      {noSelect ? (
        <BuildReset
          onClose={onClose}
          destructive={destructive}
          noSelect={noSelect}
          selectedChipset={selectedChipset}
          overrideLocale={overrideLocale}
          manuallyClose={manuallyClose}
          background={background}
          isReset={isReset}
        />
      ) : (
        <BuildStart
          marketingProducts={marketingProducts}
          onClose={onClose}
          destructive={destructive}
          noSelect={noSelect}
          selectedChipset={selectedChipset}
          overrideLocale={overrideLocale}
          fpsByPrice={fpsByPrice}
          manuallyClose={manuallyClose}
          regionalPricing={regionalPricing}
          background={background}
          isReset={isReset}
        />
      )}
    </Modal>
  </div>
);

export default BuildInterstitial;
