import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import GTM from '@utils/gtm';
import useCreateDraft from '@framework/configurator/use-create-draft';
import { ROUTE_BUILD } from '@constants';

type Props = {
  chipset: string;
  product: number;
  price: string;
  destructive?: boolean;
  overrideLocale?: string;
  onClose?: () => void;
  manuallyClose?: boolean;
};

const RouteToBld: FC<Props> = ({
  chipset,
  product,
  price,
  destructive,
  overrideLocale,
  onClose,
  manuallyClose,
}) => {
  const [draftId, setDraftId] = useState<string>();
  const createDraft = useCreateDraft();
  const router = useRouter();

  useEffect(() => {
    const fetchDraftId = async (): Promise<void> => {
      const result = await createDraft({
        chipset,
        product,
        price,
        overrideLocale,
      });
      setDraftId(result);
    };

    fetchDraftId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleRouteChangeEnd = (): void => {
      if (onClose) {
        onClose();
      }
    };

    if (draftId) {
      GTM.dataLayer({
        dataLayer: {
          event: 'StartBuild',
          SelectedChipset: chipset,
          SelectedBudget: price,
        },
      });

      if (destructive) {
        window.location.href = overrideLocale
          ? `/${overrideLocale}/${ROUTE_BUILD}/pc/${draftId}`
          : `/${ROUTE_BUILD}/pc/${draftId}`;
      } else {
        if (manuallyClose) {
          router.events.on('routeChangeComplete', handleRouteChangeEnd);
        }

        router.push(`/${ROUTE_BUILD}/pc/${draftId}`);
      }
    }

    return () => {
      if (manuallyClose) {
        router.events.off('routeChangeComplete', handleRouteChangeEnd);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destructive, draftId, overrideLocale]);

  return null;
};

export default RouteToBld;
