/* eslint-disable no-underscore-dangle */
import { FC, memo } from 'react';
import cn from 'clsx';
import type { DatoProductImage, LinkedItem } from '@framework/api/types';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import SectionWrapper from '@components/SectionWrapper';
import ContentWrapper from '@components/ContentWrapper';
import ButtonLink from '@components/ButtonLink';
import { VideoPlayer } from 'react-datocms';
import extraStyles from './extraStyles.module.css';
import * as styles from './styles';

export interface Props {
  id?: string;
  video: { video: { mp4Url: string } };
  videoPoster: DatoProductImage;
  eyebrow?: string;
  heading?: string;
  subheading?: string;
  darkText?: boolean;
  buttonLabel?: string;
  buttonLink?: LinkedItem;
  noWrapperSpacing?: boolean;
  rounded?: boolean;
  variableSpacing?: string;
  isFullBleed?: boolean;
  internalButtonLink?: LinkedItem;
}

const VideoBlock: FC<Props> = ({
  eyebrow,
  heading,
  subheading,
  video,
  videoPoster,
  buttonLabel,
  buttonLink,
  darkText,
  noWrapperSpacing = false,
  rounded = false,
  variableSpacing,
  isFullBleed = true,
}) => {
  const internalHref = buttonLink
    ? getCmsLinkUrl({
        contentType: buttonLink._modelApiKey,
        pageType: buttonLink.pageType,
        slug: buttonLink.slug,
        parentSlug: buttonLink?.parentCategory?.slug,
      })
    : null;

  return video?.video?.mp4Url && videoPoster?.responsiveImage?.src ? (
    <SectionWrapper
      noSpacing={noWrapperSpacing}
      className={styles.videoSection(rounded)}
      data-test-id="video-block"
      variableSpacing={variableSpacing}
    >
      <ContentWrapper
        fullBleed={isFullBleed}
        className={styles.contentWrapper(isFullBleed)}
        dataTestId="video-block-content-wrapper"
      >
        <VideoPlayer
          data={video?.video}
          autoPlay="muted"
          accentColor="hsl(267 83% 49%)"
          loop
        />
        {(heading || (internalHref && buttonLabel)) && (
          <div className={styles.textContainer}>
            {eyebrow && (
              <p
                className={cn(styles.eyebrow(darkText), {
                  [extraStyles.glowLight]: !darkText,
                  [extraStyles.glowDark]: darkText,
                })}
                data-test-id="video-block-eyebrow"
              >
                {eyebrow}
              </p>
            )}
            {heading && (
              <p
                className={cn(styles.heading(darkText), {
                  [extraStyles.glowLight]: !darkText,
                  [extraStyles.glowDark]: darkText,
                })}
                data-test-id="video-block-heading"
              >
                {heading}
              </p>
            )}
            {subheading && (
              <p
                className={cn(styles.subheading(darkText), {
                  [extraStyles.glowLight]: !darkText,
                  [extraStyles.glowDark]: darkText,
                })}
                data-test-id="video-block-subheading"
              >
                {subheading}
              </p>
            )}
            {internalHref && buttonLabel && (
              <ButtonLink
                buttonStyle="primary"
                dark={!darkText}
                href={internalHref}
                internalLink
                className={styles.buttonContainer}
                dataTestId="video-block-button"
              >
                <span
                  className={cn({
                    [extraStyles.glowLight]: !darkText,
                    [extraStyles.glowDark]: darkText,
                  })}
                  data-test-id="video-block-button-label"
                >
                  {buttonLabel}
                </span>
              </ButtonLink>
            )}
          </div>
        )}
      </ContentWrapper>
    </SectionWrapper>
  ) : null;
};

export default memo(VideoBlock);
