import { track } from '@amplitude/analytics-browser';
import type { BaseProperties } from '../types';

type TrackChipsetModalpArgs = {
  referrer?: string;
};

const trackChipsetModal = ({ referrer }: TrackChipsetModalpArgs): void => {
  const eventProperties: BaseProperties = {
    'page url': window.location.href,
    'referrer source': referrer,
  };

  track('configurator - chipset modal viewed', eventProperties);
};

export default trackChipsetModal;
