import { classnames } from '@tailwindcss-classnames';

const textWhite = 'text-white';
const textGrey200 = 'text-nzxt-dark-grey-200';
const textGrey500 = 'text-nzxt-dark-grey-500';
const textGrey800 = 'text-nzxt-dark-grey-800';
const textLightGrey800 = 'text-nzxt-light-grey-800';
const bgGrey800 = classnames('bg-nzxt-dark-grey-800');
const bgGrey900 = classnames('bg-nzxt-dark-grey-900');
const bgGray100 = classnames('bg-nzxt-light-grey-200');
const bgWhite = classnames('bg-white', 'border', 'border-nzxt-light-grey-200');

const flexVerticalCenter = classnames('flex', 'items-center');

export const container = classnames(
  'flex',
  'flex-col',
  'items-center',
  'mx-auto',
  'w-full',
  'pb-2'
);
export const srOnly = classnames('sr-only');

export const selectContainer = classnames(
  'lg:mb-2',
  'flex',
  'flex-col',
  'justify-center',
  'max-w-4xl',
  'w-full',
  'gap-4',
  'md:px-6',
  'space-y-6'
);

export const selectWrapper = classnames('w-full');

export const modalHeader = (isInverted?: boolean): string =>
  classnames('h5', 'mb-1', 'font-semibold', {
    [textWhite]: isInverted,
  });

export const chipsetButtonInnerWrapper = classnames('px-4', 'md:px-6');
export const chipsetInnerWrapper = classnames('md:space-x-6', 'lg:space-x-2');

export const buttonLabel = (isInverted?: boolean): string =>
  classnames('uppercase', 'font-bold', 'mb-3', {
    [textGrey500]: !isInverted,
    [textWhite]: isInverted,
  });

export const confirmationMessage = classnames('text-lg', 'my-6');

export const productSectionWrapper = classnames(
  'grid',
  'grid-cols-3',
  'gap-4',
  'pt-6'
);
export const productSectionGridItem = classnames(
  'md:col-span-1',
  'col-span-3',
  'rounded-md',
  'flex',
  'overflow-hidden',
  'flex-col',
  'p-4'
);
export const productSectionEyebrow = classnames(
  'w-full',
  'text-xs',
  'font-semibold'
);
export const imageWrapper = classnames('mx-auto', 'py-1');
export const productHeading = classnames(
  'md:text-sm',
  'lg:text-base',
  'font-bold',
  'text-nzxt-light-grey-800',
  'mt-2'
);

export const productSubheading = classnames(
  'text-nzxt-light-grey-800',
  'text-xs'
);

const flexCenter = classnames('flex', 'items-center');
export const relative = classnames('relative', 'mt-2');
export const prebuildExcerptWrapper = classnames(
  'absolute',
  'inset-0',
  flexCenter
);
export const prebuildExcerptBorder = classnames(
  'w-full',
  'border-t',
  'border-gray-300'
);
export const prebuildExcerptWithCopyWrapper = classnames(
  'relative',
  'flex',
  'justify-center',
  'text-sm'
);

const prebuildExcerptLightStyles = classnames(bgWhite, 'text-gray-500');
const prebuildExcerptDarkStyles = classnames(bgGrey800, 'text-white');
export const prebuildExcerptWithCopy = (isInverted?: boolean): string =>
  classnames('px-2', {
    [prebuildExcerptLightStyles]: !isInverted,
    [prebuildExcerptDarkStyles]: isInverted,
  });

export const chipHelpSvg = classnames('h-4', 'w-4', 'text-nzxt-blue-300');

export const chipHelpTextWrapper = (isInverted?: boolean): string =>
  classnames(
    flexVerticalCenter,
    'md:mt-6',
    'mt-2',
    'text-sm',
    'justify-center',
    'mx-auto',
    {
      [textGrey800]: !isInverted,
      [textGrey200]: isInverted,
    }
  );

export const chipHelpSvgWrapper = classnames('mr-2');

export const getActiveList = classnames(
  'mt-2',
  'transform',
  'duration-200',
  'transition-all',
  'text-xs'
);

export const estimatedPerf = classnames(
  'underline',
  'text-xs',
  'text-nzxt-light-grey-800'
);

export const buttonWrapper = classnames('flex', 'mt-2', 'items-center');
export const smolButton = classnames('w-full', 'md:w-auto');
export const cancelButton = classnames(
  smolButton,
  'mt-4',
  'md:mt-0',
  'md:ml-4'
);
export const goButton = classnames(cancelButton, 'ml-4');
export const continueToButton = classnames('mt-4', 'md:mt-8');

export const noSelectWrapper = classnames(
  'inline-block',
  'align-bottom',
  'text-left',
  'sm:align-middle',
  'sm:max-w-lg',
  'sm:w-full'
);
export const noSelectFlexWrapper = classnames('sm:flex', 'sm:items-start');
export const noSelectInner = classnames(
  'mx-auto',
  'flex-shrink-0',
  'flex',
  'items-center',
  'justify-center',
  'h-12',
  'w-12',
  'rounded-full',
  'bg-red-100',
  'sm:mx-0',
  'sm:h-10',
  'sm:w-10'
);
export const noSelectIcon = classnames('h-6', 'w-6', 'text-red-600');
export const noSelectTextContainer = classnames(
  'mt-3',
  'text-center',
  'sm:mt-0',
  'sm:ml-4',
  'sm:text-left'
);

export const noSelectHeading = classnames(
  'text-lg',
  'leading-6',
  'font-semibold',
  'text-nzxt-light-grey-900'
);

export const noSelectMessageWrapper = classnames('mt-4');

export const noSelectMessage = classnames(
  'text-sm',
  'mb-4',
  'md:mb-0',
  'text-nzxt-light-grey-700'
);

export const noSelectButtonWrapper = classnames(
  'lg:mt-6',
  'sm:mt-4',
  'sm:ml-10',
  'sm:pl-4',
  'sm:flex'
);

export const bldChoicesWrapper = classnames(
  'pt-3',
  'order-first',
  'md:order-2',
  'flex',
  'flex-col',
  'items-center'
);
export const bldChipsetWrapper = classnames(
  'pb-4',
  'md:pb-8',
  'flex',
  'flex-col',
  'items-center'
);

const withFps = classnames('grid', 'grid-cols-1', 'md:grid-cols-2');
const noFps = classnames('flex', 'items-center', 'justify-center');
export const gridWrapper = (hasFps?: boolean): string =>
  classnames('w-full', 'gap-4', 'md:gap-8', 'my-4', {
    [withFps]: hasFps,
    [noFps]: !hasFps,
  });

export const darkStyles = classnames('bg-nzxt-light-grey-800');
export const lightStyles = classnames('bg-nzxt-light-grey-100');
export const fpsWrapper = classnames(
  'md:px-4',
  'mb-4',
  'pb-4',
  'rounded-lg',
  'px-6',
  'bg-nzxt-light-grey-100'
);

export const priceButtonsDarkStyles = classnames(
  'bg-nzxt-light-grey-800',
  'hover:bg-nzxt-light-grey-600'
);
export const priceButtons = (isInverted?: boolean): string =>
  classnames('hover:bg-opacity-75', 'px-4', 'py-2', {
    [bgGray100]: !isInverted,
    [priceButtonsDarkStyles]: isInverted,
  });

export const arrows = (isInverted?: boolean): string =>
  classnames('h-6', 'stroke-current', 'h-6', 'stroke-current', {
    [textLightGrey800]: !isInverted,
    [textWhite]: isInverted,
  });

export const priceButtonWrapper = (isInverted?: boolean): string =>
  classnames(
    'flex',
    'rounded-md',
    'overflow-hidden',
    'items-center',
    'max-w-max',
    'justify-between',
    'mb-4',
    'md:mb-0',
    {
      [bgWhite]: !isInverted,
      [bgGrey900]: isInverted,
    }
  );

export const price = (isInverted?: boolean): string =>
  classnames('px-4', {
    [textGrey800]: !isInverted,
    [textWhite]: isInverted,
  });
