/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, FC, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslation as t } from '@utils/hooks';
import { IconChevronDown } from '@nzxt/react-icons';
import { onRegionChange } from '@components/CookieControl/Klaviyo';
import { getRegion } from '@framework/api/utils/maxify';
import '@reach/menu-button/styles.css';
import {
  LANGUAGES,
  LANGS_PER_REGION,
  DEFAULT_LANGUAGE_CODE,
  DEFAULT_COUNTRY_CODE,
  NORTHERN_AMERICA,
  EUROPE,
  OCEANIA,
  ASIA,
  UK_AU_NZ_REDIR_SLUGS,
} from '@constants';
import parseLocale from '@utils/parse-locale';
import * as styles from './styles';

type Props = {
  layout?: 'vertical' | 'horizontal';
  isInverted?: boolean;
  footer?: boolean;
};

const DEPRECATE_LANGS =
  process.env.NEXT_PUBLIC_FEATURE_LANG_DEPRECATION === 'true';

const FooterLangControl: FC<Props> = ({
  layout = 'horizontal',
  isInverted = true,
}) => {
  const router = useRouter();
  const { locale, defaultLocale, asPath } = router;
  const region = getRegion(locale);

  const [disabled, setDisabled] = useState(false);
  const [lang, setLang] = useState(DEFAULT_LANGUAGE_CODE);
  const [location, setLocation] = useState(DEFAULT_COUNTRY_CODE);

  const LABEL_LANGUAGE = t('label_language');
  const LABEL_COUNTRY = t('label_country');
  const REGION_NORTH_AMERICA = t('region_na');
  const REGION_OCEANIA = t('region_oceania');
  const REGION_EUROPE = t('region_europe');
  const REGION_ASIA = t('region_asia');

  const langs = DEPRECATE_LANGS ? LANGS_PER_REGION[region] : LANGUAGES;

  const REDIR_LOCATIONS = ['gb', 'nz', 'au'];

  const switchLocale = (newLang: string, newLocation: string): void => {
    const localeStr = `${newLang.toLowerCase()}-${newLocation.toUpperCase()}`;
    if (location !== newLocation) onRegionChange(localeStr);
    setLang(newLang);
    setLocation(newLocation);

    if (
      REDIR_LOCATIONS.includes(newLocation.toLowerCase()) &&
      UK_AU_NZ_REDIR_SLUGS.includes(asPath)
    ) {
      router.push('/', '/', {
        locale: `${newLang.toLowerCase()}-${newLocation.toUpperCase()}`,
      });
    } else {
      router.push(asPath, asPath, {
        locale: `${newLang.toLowerCase()}-${newLocation.toUpperCase()}`,
      });
    }
  };

  useEffect(() => {
    const parsedLocale = parseLocale(defaultLocale, locale);
    setLang(parsedLocale[0] || DEFAULT_LANGUAGE_CODE);
    setLocation(parsedLocale[1] || DEFAULT_COUNTRY_CODE);
  }, [locale, defaultLocale]);

  useEffect(() => {
    const handleRouteChangeStart = (): void => {
      setDisabled(true);
    };

    const handleRouteChangeEnd = (): void => {
      setDisabled(false);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeEnd);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeEnd);
    };
  }, [router.events]);

  const LANGUAGE_MAP = {
    en: 'English',
    de: 'Deutsch',
    nl: 'Nederlands',
    fr: 'Français',
    it: 'Italiano',
    es: 'Español',
    ...(process.env.NEXT_PUBLIC_FEATURE_ENABLE_JAPAN === 'true'
      ? { ja: '日本語' }
      : {}),
  };

  const COUNTRY_TRANSLATION_MAP = {
    US: t('country_us'),
    CA: t('country_canada'),
    AT: t('country_austria'),
    BE: t('country_belgium'),
    FR: t('country_france'),
    IT: t('country_italy'),
    LU: t('country_luxembourg'),
    DE: t('country_germany'),
    NL: t('country_netherlands'),
    ES: t('country_spain'),
    GB: t('country_uk'),
    AU: t('country_australia'),
    NZ: t('country_nz'),
    ...(process.env.NEXT_PUBLIC_FEATURE_ENABLE_JAPAN === 'true'
      ? { JP: t('country_japan') }
      : {}),
  };

  const layoutType = layout === 'horizontal';

  return (
    <div className={styles.outer}>
      <form className={styles.form}>
        <fieldset className={styles.getFieldset(layoutType)}>
          <div className={styles.fieldWrapper}>
            <label htmlFor="language" className={styles.getLabel(isInverted)}>
              {LABEL_LANGUAGE}
            </label>
            <div className={styles.relative}>
              <select
                data-test-id="language"
                id="language"
                value={lang}
                onChange={e => switchLocale(e.currentTarget.value, location)}
                className={styles.getSelect(isInverted)}
                disabled={disabled}
              >
                {langs.map(l => (
                  <option key={l.value} value={l.value}>
                    {LANGUAGE_MAP[l.value]}
                  </option>
                ))}
              </select>
              <div className={styles.iconWrapper} aria-hidden="true">
                <IconChevronDown
                  className={styles.getChevronDown(isInverted)}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldWrapper}>
            <label htmlFor="location" className={styles.getLabel(isInverted)}>
              {LABEL_COUNTRY}
            </label>
            <div className={styles.relative}>
              <select
                id="location"
                data-test-id="location"
                className={styles.getSelect(isInverted)}
                value={location}
                onChange={e => switchLocale(lang, e.currentTarget.value)}
                disabled={disabled}
              >
                <optgroup label={REGION_NORTH_AMERICA}>
                  {NORTHERN_AMERICA.map(c => (
                    <option key={c.value} value={c.value}>
                      {COUNTRY_TRANSLATION_MAP[c.value]}
                    </option>
                  ))}
                </optgroup>
                <optgroup label={REGION_EUROPE}>
                  {EUROPE.map(c => (
                    <option key={c.value} value={c.value}>
                      {COUNTRY_TRANSLATION_MAP[c.value]}
                    </option>
                  ))}
                </optgroup>
                <optgroup label={REGION_OCEANIA}>
                  {OCEANIA.map(c => (
                    <option key={c.value} value={c.value}>
                      {COUNTRY_TRANSLATION_MAP[c.value]}
                    </option>
                  ))}
                </optgroup>
                {process.env.NEXT_PUBLIC_FEATURE_ENABLE_JAPAN === 'true' && (
                  <optgroup label={REGION_ASIA}>
                    {ASIA.map(c => (
                      <option key={c.value} value={c.value}>
                        {COUNTRY_TRANSLATION_MAP[c.value]}
                      </option>
                    ))}
                  </optgroup>
                )}
              </select>
              <div className={styles.iconWrapper} aria-hidden="true">
                <IconChevronDown
                  className={styles.getChevronDown(isInverted)}
                />
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default FooterLangControl;
