var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5859631d7b9e02756aeffb6850ae4bf534ce7c54"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { STAGING } from '@constants';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const environment =
  process.env.VERCEL_GITHUB_COMMIT_REF === STAGING
    ? process.env.NEXT_PUBLIC_STAGING_ENV_NAME
    : process.env.NEXT_PUBLIC_ENV_NAME;

Sentry.init({
  enabled: process.env.NODE_ENV !== 'development',
  dsn:
    SENTRY_DSN ||
    'https://785f98e05ef24ca1b6428473599e262d@o195198.ingest.sentry.io/5493557',
  environment,
  sampleRate: 0.5,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
