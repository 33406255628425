/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, Fragment, useState, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { IconChevronLeft, IconChevronRight } from '@nzxt/react-icons';
import { getRegion } from '@framework/api/utils/maxify';
import usePrice from '@framework/use-price';
import { useTranslation as t } from '@utils/hooks';
import GTM from '@utils/gtm';
import {
  CONFIGURATOR_DEFAULT_PRICE,
  PRICE_OPTIONS_LABEL,
  MAXIFY_CURRENCY_MAP_PER_REGION,
} from '@constants';

import * as styles from './styles';

type Props = {
  setPrice: (p: string) => void;
  regionalPricing?: {
    price: string;
    id: string;
  }[];
  isInverted?: boolean;
  price?: string;
};

const BuildInterstitial: FC<Props> = ({
  regionalPricing,
  isInverted,
  setPrice,
  price,
}) => {
  const router = useRouter();
  const { locale } = router;
  const region = getRegion(locale);

  const currencyCode = useMemo(
    () => MAXIFY_CURRENCY_MAP_PER_REGION[region],
    [region]
  );

  const hasRegionalPricing =
    Array.isArray(regionalPricing) && regionalPricing?.length > 0;
  const sortedRegionPricing = useMemo(
    () =>
      hasRegionalPricing
        ? regionalPricing.sort((a, b) => (a.price > b.price ? 1 : -1))
        : [],
    [hasRegionalPricing, regionalPricing]
  );

  const initialSlideIndex =
    sortedRegionPricing.length > 0
      ? sortedRegionPricing.indexOf(
          sortedRegionPricing[Math.round((sortedRegionPricing.length - 1) / 2)]
        )
      : PRICE_OPTIONS_LABEL.findIndex(
          item => item.value === CONFIGURATOR_DEFAULT_PRICE
        );
  const [currentSlide, setCurrentSlide] = useState(initialSlideIndex);

  const pricingByDatoOrConstant =
    sortedRegionPricing.length > 0 ? sortedRegionPricing : PRICE_OPTIONS_LABEL;

  useEffect(() => {
    setPrice(
      sortedRegionPricing.length > 0
        ? sortedRegionPricing[currentSlide].price
        : PRICE_OPTIONS_LABEL[currentSlide].value
    );
  }, [currentSlide, sortedRegionPricing]);

  const { price: itemPrice } = usePrice({
    amount: parseInt(price, 10),
    currencyCode,
    maximumSignificantDigits: 2,
  });

  const priceWithoutZero = itemPrice
    .replace('.', '')
    .replace(',', '')
    .includes(
      sortedRegionPricing.length > 0
        ? sortedRegionPricing[sortedRegionPricing.length - 1].price
        : '2500'
    )
    ? `${itemPrice}+`
    : itemPrice;

  const handleGtmEvent = (event: string): void => {
    GTM.dataLayer({
      dataLayer: {
        event,
      },
    });
  };

  const nextButton = (): void => {
    setCurrentSlide(currentSlide + 1);
    handleGtmEvent('Config_PC_BudgetToggle');
  };

  const previousButton = (): void => {
    setCurrentSlide(currentSlide - 1);
    handleGtmEvent('Config_PC_BudgetToggle');
  };

  const MESSAGE_BUDGET = t('custom_bld_budget');
  const INCREASE_LABEL = t('increase_price_label');
  const DECREASE_LABEL = t('decrease_price_label');

  return (
    <>
      <p className={styles.buttonLabel(!isInverted)}>{MESSAGE_BUDGET}</p>
      {pricingByDatoOrConstant.map((bldPrice, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`${i}-budget-button`}>
          {currentSlide === i && (
            <div className={styles.priceButtonWrapper(!isInverted)}>
              <button
                type="button"
                className={styles.priceButtons(!isInverted)}
                onClick={() => previousButton()}
                disabled={currentSlide === 0}
              >
                <p className={styles.srOnly}>{DECREASE_LABEL}</p>
                <IconChevronLeft className={styles.arrows(!isInverted)} />
              </button>
              <span className={styles.price(!isInverted)}>
                {priceWithoutZero}
              </span>
              <button
                type="button"
                className={styles.priceButtons(!isInverted)}
                onClick={() => nextButton()}
                disabled={currentSlide === pricingByDatoOrConstant.length - 1}
              >
                <p className={styles.srOnly}>{INCREASE_LABEL}</p>
                <IconChevronRight className={styles.arrows(!isInverted)} />
              </button>
            </div>
          )}
        </Fragment>
      ))}
    </>
  );
};

export default BuildInterstitial;
