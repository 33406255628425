import { useMemo } from 'react';
import regionFeatures from '../../config/regionFeatures';
import type { RegionFeatures, RegionName } from '../../config/regionFeatures';

const useRegionFeatures = (region: string): RegionFeatures => {
  const visibility = useMemo(
    () => regionFeatures[region as RegionName],
    [region]
  );

  return visibility;
};

export default useRegionFeatures;
