import { FC, Fragment, memo, useState, useEffect } from 'react';
import GTM from '@utils/gtm';
import { useTranslation as t } from '@utils/hooks';
import { useRouter } from 'next/router';
import { getRegion } from '@framework/api/utils/maxify';
import ToggleButton from '@components/ToggleButton';
import Image from '@components/Image';
import type { Fps } from '@framework/api/types';
import TextConjunction from '@components/TextConjunction';
import { FPS_BUTTON_LABEL } from '@constants';

import * as styles from './styles';

type Props = {
  fps: Fps;
  isDefaultButton?: boolean;
  isSmall?: boolean;
  isInverted?: boolean;
  isBorderedHeading?: boolean;
  updateFps?: (fps: string) => void;
  externalSelectedFps?: string;
  chipsetType?: string;
  hideHeading?: boolean;
};

const DATA_MAP = {
  intel: 'gameFpsIntel',
  amd: 'gameFpsAmd',
};

const FpsSection: FC<Props> = ({
  fps,
  isInverted = false,
  isDefaultButton,
  isSmall,
  updateFps,
  externalSelectedFps,
  isBorderedHeading,
  chipsetType,
  hideHeading = false,
}) => {
  const [selectedFps, setSelectedFps] = useState(externalSelectedFps || '1080');
  const handleClick = (val: string): void => {
    if (val !== selectedFps) {
      setSelectedFps(val);
    }

    if (externalSelectedFps && val !== externalSelectedFps) {
      updateFps(val);
    }

    GTM.dataLayer({
      dataLayer: {
        event: 'Config_PC_FPSToggle',
      },
    });
  };
  const { locale } = useRouter();
  const region = getRegion(locale);

  useEffect(() => {
    setSelectedFps('1080');
  }, [region]);

  const PERFORMANCE = t('performance');
  return fps && Array.isArray(fps) && fps.length > 0 ? (
    <>
      {!hideHeading && isBorderedHeading && (
        <TextConjunction headingStyle text={PERFORMANCE} />
      )}
      <div className={styles.fpsWrapper(isSmall)}>
        {!isBorderedHeading && (
          <p className={styles.fpsHeading(isInverted)}>{PERFORMANCE}</p>
        )}
        <div className={styles.cardsGrid(isSmall)}>
          {fps.map(game => (
            <Fragment key={game.id}>
              {selectedFps === game.fpsSetting &&
                game?.games &&
                Array.isArray(game?.games) &&
                game.games.map((item, i) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item.gameFpsAmd || item.gameFpsIntel}-${i}`}
                    className={styles.detailsWrapper(isSmall)}
                  >
                    {item?.gameLogo && (
                      <Image
                        data={item?.gameLogo.responsiveImage}
                        width={item?.gameLogo.responsiveImage.width}
                        height={item?.gameLogo.responsiveImage.height}
                        loading="eager"
                        className={styles.gameLogo}
                      />
                    )}
                    {chipsetType && (item.gameFpsAmd || item.gameFpsIntel) && (
                      <p className={styles.gameFps} data-test-id="fps-value">
                        {item[DATA_MAP[chipsetType]]}
                        <span className={styles.fpsText}>FPS</span>
                      </p>
                    )}
                  </div>
                ))}
            </Fragment>
          ))}
        </div>
        <ToggleButton
          handleClick={handleClick}
          activeItem={selectedFps}
          options={FPS_BUTTON_LABEL}
          isNotCentered
          isPurple={!isDefaultButton}
          isInverted={isInverted}
        />
      </div>
    </>
  ) : null;
};

export default memo(FpsSection);
