/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, Fragment } from 'react';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@reach/disclosure';
import '@reach/menu-button/styles.css';
import { useTranslation as t, useMedia } from '@utils/hooks';
import { ResponsiveImageFragment } from '@framework/api/utils/dato/responsive-image-fragment';
import FpsSection from '@components/FpsSection';

import * as styles from './styles';

type FpsProps = {
  id: string;
  fpsSetting: string;
  games: {
    id: string;
    gameLogo: { responsiveImage: ResponsiveImageFragment };
    gameFps: string;
    gameFpsAmd?: string;
    gameFpsIntel?: string;
  }[];
};

export type FpsRangeCollection = {
  priceRangeFpsCollection: {
    buildPrice?: string;
    gameReference?: FpsProps[];
  };
};

type Props = {
  selectedChipset?: string;
  fpsByPrice?: FpsRangeCollection;
  selectedPrice: string;
  setSelectedFps?: (it: string) => void;
  selectedFps?: string;
};

const FpsRange: FC<Props> = ({
  selectedChipset,
  fpsByPrice,
  selectedPrice,
  setSelectedFps,
  selectedFps,
}) => {
  const isSm = useMedia('sm');
  const ESTIMATED = t('see_estimated_performance_label');

  const hasPriceMatchFps =
    Array.isArray(fpsByPrice) &&
    fpsByPrice?.length > 0 &&
    fpsByPrice?.some(item => item.buildPrice === selectedPrice);

  return (
    <>
      {isSm ? (
        <Disclosure>
          <DisclosureButton>
            <p className={styles.estimatedPerf}>{ESTIMATED}</p>
          </DisclosureButton>
          <DisclosurePanel>
            {hasPriceMatchFps &&
              Array.isArray(fpsByPrice) &&
              fpsByPrice.length > 0 && (
                <div className={styles.fpsWrapper}>
                  {fpsByPrice.map(fps => (
                    <Fragment key={fps?.buildPrice}>
                      {fps?.buildPrice === selectedPrice && (
                        <FpsSection
                          fps={fps.gameReference}
                          updateFps={setSelectedFps}
                          externalSelectedFps={selectedFps}
                          chipsetType={selectedChipset}
                          isSmall
                          isDefaultButton
                        />
                      )}
                    </Fragment>
                  ))}
                </div>
              )}
          </DisclosurePanel>
        </Disclosure>
      ) : (
        <>
          {hasPriceMatchFps &&
            Array.isArray(fpsByPrice) &&
            fpsByPrice.length > 0 && (
              <div className={styles.fpsWrapper}>
                {fpsByPrice.map(fps => (
                  <Fragment key={fps?.buildPrice}>
                    {fps?.buildPrice === selectedPrice && (
                      <FpsSection
                        fps={fps.gameReference}
                        updateFps={setSelectedFps}
                        externalSelectedFps={selectedFps}
                        chipsetType={selectedChipset}
                        isSmall
                        isDefaultButton
                      />
                    )}
                  </Fragment>
                ))}
              </div>
            )}
        </>
      )}
    </>
  );
};

export default FpsRange;
