/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback } from 'react';
import type { HookFetcher } from '../utils/types';
import useCommerceCreateDraft from '../commerce/configurator/use-create-draft';
import { BuildOptions } from '../api/configurator-draft';

const defaultOpts = {
  url: '/api/maxify/configurator-draft',
  method: 'POST',
};

type CreateDraftInput = BuildOptions;

export const fetcher: HookFetcher<string, CreateDraftInput> = (
  options,
  { chipset, product = null, price, overrideLocale = null },
  fetch
) =>
  chipset && price
    ? fetch({
        ...defaultOpts,
        ...options,
        body: { chipset, product, price, overrideLocale },
      })
    : null;

export function extendHook(customFetcher: typeof fetcher) {
  const useCreateDraft = () => {
    const fn = useCommerceCreateDraft(defaultOpts, customFetcher);

    return useCallback(
      async function createDraft(input: CreateDraftInput) {
        const data = await fn(input);
        return data;
      },
      [fn]
    );
  };

  useCreateDraft.extend = extendHook;

  return useCreateDraft;
}

export default extendHook(fetcher);
