/* eslint-disable no-underscore-dangle */
import { FC, memo, useMemo } from 'react';
import cx from 'clsx';
import type { ButtonGroup } from '@framework/api/types';
import ButtonLink from '@components/ButtonLink';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import * as styles from './styles';

interface Props {
  links?: ButtonGroup[];
  alignment?: 'center' | 'left';
  isInverted?: boolean;
  lg?: boolean;
  abCfgRoute?: boolean;
  setModalOpen?: (b: boolean) => void;
}

const LinkGroup: FC<Props> = ({
  links,
  alignment,
  isInverted = false,
  lg = false,
  abCfgRoute = false,
  setModalOpen,
}) => {
  const hasButtons =
    Array.isArray(links) && links?.filter(butt => butt?.linkLabel);
  const composedClassNameButton = useMemo(
    () =>
      cx(styles.buttonContainer[alignment || 'center'], {
        [styles.textSize]: lg,
      }),

    [alignment, lg]
  );
  return hasButtons ? (
    <div className={composedClassNameButton} data-test-id="link-group-block">
      {links?.map(item => {
        const link = Array.isArray(item?.internalLink)
          ? item?.internalLink[0]
          : item?.internalLink;

        const internalLink = item?.internalLink
          ? getCmsLinkUrl({
              contentType: link._modelApiKey,
              pageType: link.pageType,
              slug: link.slug,
              parentSlug: link?.parentCategory?.slug,
            })
          : null;

        const linkType = item?.linkType?.toLowerCase();

        const handleClick = (e): void => {
          e.preventDefault();
          setModalOpen(true);
        };

        return (
          <ButtonLink
            key={item?.id}
            onClick={abCfgRoute ? handleClick : undefined}
            asButton={item?.asButton}
            buttonStyle={linkType}
            href={internalLink || item?.externalLinkRef?.url}
            customMargin
            target={item?.newTab ? '_blank' : undefined}
            rel={item?.newTab ? 'noopener noreferrer' : undefined}
            dark={isInverted}
            internalLink={Boolean(internalLink)}
            dataTestId={`${item?.id}-button`}
            aria-label={`${item?.linkLabel} ${
              internalLink
                ? `${process.env.NEXT_PUBLIC_BASE_URL}${internalLink}`
                : item?.externalLinkRef?.url
            }`}
          >
            {item?.linkLabel}
          </ButtonLink>
        );
      })}
    </div>
  ) : null;
};

export default memo(LinkGroup);
