import {
  MAXIFY_REGION_NORTHERN_AMERICA,
  MAXIFY_REGION_EUROPE,
  MAXIFY_REGION_ASIA,
  MAXIFY_REGION_NEW_ZEALAND,
  MAXIFY_REGION_OCEANIA,
  MAXIFY_REGION_UNITED_KINGDOM,
} from 'constants/location';

export type RegionName =
  | typeof MAXIFY_REGION_NORTHERN_AMERICA
  | typeof MAXIFY_REGION_EUROPE
  | typeof MAXIFY_REGION_ASIA
  | typeof MAXIFY_REGION_NEW_ZEALAND
  | typeof MAXIFY_REGION_OCEANIA
  | typeof MAXIFY_REGION_UNITED_KINGDOM;

type RegionConfig = {
  [K in RegionName]: RegionFeatures;
};

export type RegionFeatures = {
  cartButton: boolean;
  signIn: boolean;
  support: boolean;
  zendeskChat: boolean;
};

const regionFeatures: RegionConfig = {
  northernAmerica: {
    cartButton: true,
    signIn: true,
    support: true,
    zendeskChat: true,
  },
  europe: {
    cartButton: true,
    signIn: true,
    support: true,
    zendeskChat: true,
  },
  asia: {
    cartButton: false,
    signIn: false,
    support: false,
    zendeskChat: false,
  },
  unitedKingdom: {
    cartButton: false,
    signIn: true,
    support: false,
    zendeskChat: true,
  },
  newZealand: {
    cartButton: false,
    signIn: false,
    support: false,
    zendeskChat: false,
  },
  oceania: {
    cartButton: false,
    signIn: false,
    support: false,
    zendeskChat: false,
  },
};

export default regionFeatures;
