/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, useCallback } from 'react';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@reach/disclosure';
import '@reach/menu-button/styles.css';
import { useRouter } from 'next/router';
import { Experiment } from '@amplitude/experiment-js-client';
import { useTranslation as t } from '@utils/hooks';
import { useHistory } from '@utils/history';
import trackChipsetModal from '@utils/amplitude/track/chipset-modal';
import trackStartFromChipsetModal from '@utils/amplitude/track/start-from-chipset-modal';
import { IconCircleQuestionMark } from '@nzxt/react-icons';
import { getRegion } from '@framework/api/utils/maxify';
import { ResponsiveImageFragment } from '@framework/api/utils/dato/responsive-image-fragment';
import type { DatoProduct } from '@framework/api/types';
import GTM from '@utils/gtm';
import useBuildStore, {
  getBldEntryVariantId,
  getSetBLDEntryVariantId,
} from '@stores/use-build-store';
import {
  CONFIGURATOR_DEFAULT_CHIPSET,
  CONFIGURATOR_DEFAULT_PRICE,
  MAXIFY_REGION_EUROPE,
  MAXIFY_REGION_NORTHERN_AMERICA,
  MAXIFY_REGION_UNITED_KINGDOM,
  ROUTE_PRODUCT,
} from '@constants';
import Button from '@components/Button';
import ToggleButton from '@components/ToggleButton';
import FpsRange from './FpsRange';
import PriceRange from './PriceRange';
import RouteToBld from './RouteToBld';
import * as styles from './styles';

type FpsProps = {
  id: string;
  fpsSetting: string;
  games: {
    id: string;
    gameLogo: { responsiveImage: ResponsiveImageFragment };
    gameFps: string;
    gameFpsAmd?: string;
    gameFpsIntel?: string;
  }[];
};

export type FpsRangeCollection = {
  priceRangeFpsCollection: {
    buildPrice?: string;
    gameReference?: FpsProps[];
  };
};

interface MarketingProductProps extends DatoProduct {
  collectionName: string;
  collectionSlug: string;
}

type Props = {
  marketingProducts?: MarketingProductProps[];
  onClose?: () => void;
  destructive?: boolean;
  noSelect?: boolean;
  selectedChipset?: string;
  overrideLocale?: string;
  fpsByPrice?: FpsRangeCollection;
  manuallyClose?: boolean;
  regionalPricing?: {
    price: string;
    id: string;
  }[];
  background?: 'transparent' | 'light' | 'dark';
  isReset?: boolean;
};

const amplitudeId = process.env.NEXT_PUBLIC_AMPLITUDE_ID;

const BuildInterstitial: FC<Props> = ({
  marketingProducts,
  onClose,
  destructive,
  noSelect,
  selectedChipset,
  overrideLocale,
  fpsByPrice,
  manuallyClose,
  regionalPricing,
  background,
  isReset,
}) => {
  const router = useRouter();
  const { locale, asPath } = router;
  const region = getRegion(locale);
  const isInverted = background === 'dark';

  const bldEntryVariantId = useBuildStore(getBldEntryVariantId);
  const setBldEntryVariantId = useBuildStore(getSetBLDEntryVariantId);

  // clear the entry variant ID when the interstitial unmounts
  useEffect(
    () => () => {
      setBldEntryVariantId(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // AB test: Configurator Routing 2
  const [experimentVariant, setExperimentVariant] = useState(null);

  useEffect(() => {
    const initExperiment = async (): Promise<void> => {
      const experiment =
        Experiment.initializeWithAmplitudeAnalytics(amplitudeId);

      await experiment.start();

      const variant = experiment.variant('configurator-routing-2');

      if (variant && variant.value) {
        setExperimentVariant(variant.value);
      }
    };

    if (amplitudeId) {
      initExperiment();
    }
  }, [asPath]);

  const abCfgRoute = experimentVariant === 'treatment';

  const [chipset, setChipset] = useState(
    selectedChipset || CONFIGURATOR_DEFAULT_CHIPSET
  );
  const [price, setPrice] = useState(CONFIGURATOR_DEFAULT_PRICE);
  const [loading, setLoading] = useState(false);
  const [selectedFps, setSelectedFps] = useState('1080');

  useEffect(() => {
    setSelectedFps('1080');
  }, [price]);

  const handleGtmEvent = (event: string): void => {
    GTM.dataLayer({
      dataLayer: {
        event,
      },
    });
  };

  const CHIPSET_OPTIONS = [
    { label: t('custom_bld_amd'), value: 'amd' },
    { label: t('custom_bld_intel'), value: 'intel' },
  ];

  const MESSAGE_DESTRUCTIVE_ACTION = t('custom_bld_destrutive_action');
  const MODAL_HEADING = t('custom_bld_modal_heading');
  const MODAL_SUBHEADING = t('custom_bld_modal_subheading');
  const MESSAGE_CANCEL = t('cancel');
  const MESSAGE_BLD_CHIPSET = t('custom_bld_chipset');
  const MESSAGE_HELP_ME_DECIDE = t('help_me_decide');
  const MESSAGE_CONTINUE = t('button_label_continue_to');
  const CHIPSET_HELP = t('custom_bld_amd_help');
  const PREBUILD_UPSELL = t('custom_bld_prebuild_upsell');
  const INFO_LABEL = t('build_info_message');

  const { last: lastPage } = useHistory();

  const hasPriceMatchFps =
    Array.isArray(fpsByPrice) &&
    fpsByPrice?.length > 0 &&
    fpsByPrice?.some(item => item.buildPrice === price);

  const handleGtmAndSetLoading = useCallback((): void => {
    GTM.dataLayer({
      dataLayer: {
        event: 'Config_PC_BuildSelect',
        fps: selectedFps,
        chipset: selectedChipset,
        budget: price,
      },
    });

    trackStartFromChipsetModal({
      referrer: lastPage,
      chipset,
      budget: price,
    });

    setLoading(true);
  }, [chipset, lastPage, price, selectedChipset, selectedFps]);

  useEffect(() => {
    if (!isReset) {
      return;
    }

    GTM.dataLayer({
      dataLayer: {
        event: 'Config_PC_BuildRestart',
        fps: selectedFps,
        chipset: selectedChipset,
        budget: price,
      },
    });
  }, [isReset]);

  const [hasBeenTrackedInAmplitude, setHasBeenTrackedInAmplitude] =
    useState(false);

  useEffect(() => {
    let timeout = null;

    if (!noSelect && !destructive && !hasBeenTrackedInAmplitude) {
      timeout = setTimeout(() => {
        trackChipsetModal({
          referrer: lastPage,
        });

        setHasBeenTrackedInAmplitude(true);
      }, 1);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [destructive, hasBeenTrackedInAmplitude, lastPage, noSelect, region]);

  // AB test: Configurator Routing 2
  const handleContinue = useCallback((): void => {
    const AB_ROUTING_TABLE = {
      intel: {
        1500: `/${ROUTE_PRODUCT}/player-two`,
        2000: `/${ROUTE_PRODUCT}/player-two-prime`,
        2500: `/${ROUTE_PRODUCT}/player-three`,
        3000: `/${ROUTE_PRODUCT}/player-three-prime`,
      },
      amd: {
        1500: `/${ROUTE_PRODUCT}/player-two`,
        2000: `/${ROUTE_PRODUCT}/player-two-prime`,
        2500: `/${ROUTE_PRODUCT}/player-three`,
        3000: `/${ROUTE_PRODUCT}/player-three-prime`,
      },
    };

    if (abCfgRoute && region === MAXIFY_REGION_NORTHERN_AMERICA) {
      const pdpRoute = AB_ROUTING_TABLE[chipset][price];

      if (typeof pdpRoute === 'string' && pdpRoute.length > 0) {
        router.push(pdpRoute);
      } else {
        handleGtmAndSetLoading();
      }
    } else {
      handleGtmAndSetLoading();
    }
  }, [abCfgRoute, chipset, handleGtmAndSetLoading, price, region, router]);

  return (
    <>
      <div className={styles.container}>
        <h1
          className={styles.modalHeader(!isInverted)}
          data-test-id="build-interstitial-heading"
        >
          {destructive ? MESSAGE_DESTRUCTIVE_ACTION : MODAL_HEADING}
        </h1>
        <h2>{MODAL_SUBHEADING}</h2>
        <div
          className={styles.gridWrapper(
            hasPriceMatchFps &&
              Array.isArray(fpsByPrice) &&
              fpsByPrice.length > 0
          )}
        >
          {hasPriceMatchFps && (
            <FpsRange
              selectedPrice={price}
              selectedChipset={chipset}
              fpsByPrice={fpsByPrice}
              setSelectedFps={setSelectedFps}
              selectedFps={selectedFps}
            />
          )}

          <div className={styles.bldChoicesWrapper}>
            <div className={styles.bldChipsetWrapper}>
              <p className={styles.buttonLabel(!isInverted)}>
                {MESSAGE_BLD_CHIPSET}
              </p>
              <ToggleButton
                handleClick={setChipset}
                activeItem={chipset}
                options={CHIPSET_OPTIONS}
                isInverted={!isInverted}
                onClick={() => handleGtmEvent('Config_PC_ChipsetToggle')}
              />
              <Disclosure>
                <DisclosureButton
                  className={styles.chipHelpTextWrapper(!isInverted)}
                >
                  <span className={styles.chipHelpSvgWrapper}>
                    <IconCircleQuestionMark
                      className={styles.chipHelpSvg}
                      aria-label={INFO_LABEL}
                    />
                  </span>
                  {MESSAGE_HELP_ME_DECIDE}
                </DisclosureButton>
                <DisclosurePanel>
                  <p className={styles.getActiveList}>{CHIPSET_HELP}</p>
                </DisclosurePanel>
              </Disclosure>
            </div>

            {price && (
              <PriceRange
                setPrice={setPrice}
                price={price}
                isInverted={isInverted}
                regionalPricing={regionalPricing}
              />
            )}

            {!destructive && (
              <Button
                buttonStyle="primary"
                onClick={() => handleContinue()}
                loading={loading}
                customMargin
                className={styles.continueToButton}
                data-test-id="continue-to-build"
              >
                {MESSAGE_CONTINUE}
              </Button>
            )}
          </div>
        </div>
        {destructive && onClose && (
          <div className={styles.buttonWrapper}>
            <>
              <Button
                buttonStyle="secondary"
                onClick={onClose}
                disabled={loading}
                customMargin
                className={styles.cancelButton}
              >
                {MESSAGE_CANCEL}
              </Button>
              <Button
                buttonStyle="primary"
                onClick={() => setLoading(true)}
                loading={loading}
                customMargin
                className={styles.goButton}
              >
                {MESSAGE_CONTINUE}
              </Button>
            </>
          </div>
        )}
        {loading && (
          <RouteToBld
            chipset={chipset}
            product={parseInt(bldEntryVariantId?.toString(), 10)}
            price={price}
            destructive={destructive}
            overrideLocale={overrideLocale}
            onClose={onClose}
            manuallyClose={manuallyClose}
          />
        )}
      </div>
      {region !== MAXIFY_REGION_EUROPE &&
        region !== MAXIFY_REGION_UNITED_KINGDOM &&
        Array.isArray(marketingProducts) &&
        marketingProducts?.length > 0 && (
          <>
            <div className={styles.relative}>
              <div className={styles.prebuildExcerptWrapper}>
                <div className={styles.prebuildExcerptBorder} />
              </div>
              <div className={styles.prebuildExcerptWithCopyWrapper}>
                <span className={styles.prebuildExcerptWithCopy(isInverted)}>
                  {PREBUILD_UPSELL}
                </span>
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default BuildInterstitial;
